export default {
  shop_center_txt: "Shop center",
  score_txt: "Points",
  minute_txt: "Minute",
  wallet_txt: "Wallet",
  today_txt: "Today",
  week_txt: "Week",
  month_txt: "Month",
  year_txt: "Year",
  main_indicators_txt: "Main indicators",
  sale_price: "Sale price",
  expected_profit: "Expected profit",
  order_count: "Order quantity",
  goods_click_count: "Number of product clicks",
  supplier_txt: "Supplier",
  friends_help_txt: "Friends help",
  feedback_txt: "Feedback",
  feedback: "Feedback",
  online_service_txt_1: "Deposit customer service",
  online_service_txt_2: "Business consultation",
  q_and_a_txt: "Question and Answer",
  open_now_txt: "Open",
  certification_activation_code: "Certification activation code",
  plz_write_invite_code: "Please fill in the invitation code",
  time_txt: "Hour",
  times_txt: "Time",

  wallet_total_assets: "Total assets",
  balance_txt: "Balance",
  pending_settlement_txt: "pending settlement",
  recharge_txt: "Recharge",
  withdraw_txt: "Withdraw",
  fund_details_txt: "Fund details",
  no_more_txt: "No more",
  no_data_txt: "No data yet",
  detail_txt: "Details",
  num_txt: "Number",
  money_txt: "Amount",
  remark_txt: "Remarks",
  no_remark_txt: "No remark",
  tip_txt: "Tip",
  tip_content_txt:
    "Due to currency issues underwritten by merchants in various countries, currently",
  need_contact_service: "Need to contact customer service",
  contact_service: "Contact customer service",

  feedback_title: "Feedback your opinion",
  feedback_placeholder:
    "For security reasons, please do not include personal information",
  submit_txt: "Submit",
  sure_txt: "OK",
  submit_suc_txt: "Submission successful",

  loading: "loading...",

  newer_action: "Newcomer activity",
  newer_traffic_package: "Newcomer support traffic package",
  newer_get_millions_of_products: "Get millions of products",
  newer_get: "Received",
  newer_receive: "Receive",
  newer_action_ruler: "Newcomer activity rules",
  newer_receive_suc: "Received successfully",

  help_friends_help: "Friends help",
  help_your_friends_stores: "Help your friends",
  get_store_product_heating: "Get store product heating",
  help_friends_txt: "Help friends",
  help_friends_activity_rules: "Help friends activity rules",
  assistance_details: "Assistance details",
  team_list: "Team List",
  help_friend_name: "Help friend name",
  plz_input: "Please enter",
  plz_input_friend_name: "Please enter the name of your friend to help",
  help_suc: "Help Success",

  online_service: "Online customer service",
  online_24_hour_service: "24-hour online support",
  customer_service: "Customer Service",
  copy_and_add_id: "Copy and add LINE ID",
  plz_input_question_detail: "Please enter the details of your question",
  harry_reply_your: "We will reply to you as soon as possible",
  click_copy: "Click to copy",
  copy_suc: "Copy successful",
  copy_fail: "Copy failed",

  overall: "Overall",
  cooperate: "Cooperate",
  cancel: "Cancel",
  supplier_num: "Supplier number",
  no_use_supplier: "No available supplier",
  here_add_supplier: "Add new supplier here",
  add_supplier: "Add supplier",
  plz_input_supplier_num: "Please enter the supplier number",
  add_suc: "Add successfully",
  remove_suc: "Removed successfully",

  new_products_launched: "New products launched",
  profit_txt: "Profit",
  has_been_added: "Listed",
  on_display: "On display",
  coming_soon_txt: "Available now",
  sold_txt: "Sold",
  removed_txt: "Removed",

  order_detail_txt: "Order details",
  waiting_express_delivery: "Waiting for express delivery",
  customer_info: "Customer information",
  order_good: "Order Goods",
  order_num: "Order number",
  order_date: "Order date",
  order_content: "Order Summary",
  purchase_price_txt: "Purchase price",
  income_txt: "Income",

  order_txt: "Order",
  pending_payment: "Pending payment",
  ready_for_shipment: "Ready for shipment",
  shipped_txt: "Shipped",
  paid_txt: "Delivered",
  good_num: "Item number",
  pay_and_ship: "Payment and Shipment",
  pay_suc: "Payment successful",
  no_order: "No order yet",
  no_order_tips: "You can track your orders and returns here",
  suer_order: "Confirm payment",
  pay_way: "payment method",
  wallet_pay: "Wallet payment",
  sure_pay: "Confirm payment",
  paying_txt: "Paying...",

  manage_showcase: "Manage showcase",
  add_new_good: "Add new product",
  heating_txt: "Heating",
  promotion: "Promotion",
  promotion_tip: "Promotion tip",
  promotion_tip_txt:
    "Your current points are less than 200 and you cannot pay for your products",
  copy_link: "Copy link",
  scan_qr_code: "Scan QR code",

  about_good: "About the product",
  specification: "Specification",
  product_overview: "Product Overview",

  top_tips_1: "Tiktok Online to Online",
  top_tips_2: "Currently only internal invitations are supported",
  active_place_holeder: "Please enter",

  promotion_01: "Promotion",
  promotion_02: "Promotion amount",
  promotion_03: "Please enter the promotion amount",
  promotion_04: "Confirm promotion",
  promotion_05: "Promotion successful",

  bao_txt: "Exposure",
  bal_no: "Insufficient balance",

  wallet_type_01: "System manual recharge",
  wallet_type_02: "Manual withdrawal",
  wallet_type_03: "Payment",
  wallet_type_04: "System deduction",
  wallet_type_05: "Profit income",
  wallet_type_06: "Payment deduction",
  wallet_type_07: "Distribution consumption",
  wallet_type_08: "Promotion revenue",
  wallet_type_09: "Boost rewards",
  wallet_type_10: "Registration gift",
  wallet_type_11: "Sales reward",
  wallet_type_12: "Withdrawal rejection",

  all_orders: "All orders",
  mine_orders: "My orders",
  mine_services: "My Services",
  _tzz: "Jumping...",

  info_01: "Name",
  info_02: "Email",
  info_03: "Contact information",
  info_04: "Gender",
  info_05: "Confidential",
  info_06: "Male",
  info_07: "Female",
  info_08: "Date of birth(yyyy-MM-dd)",
  info_09: "Country",
  info_10: "City",
  info_11: "Card number",
  info_12: "Card type",
  info_13: "Card Issuer",
  info_14: "Expiration date(yyyy-MM-dd)",
  info_15: "CVV code",
  info_16: "Security password",
  info_17: "Repeat security password",
  info_18: "Please enter the security password again",
  info_19: "The two security passwords are inconsistent",
  info_20: "ID card/passport",
  info_21: "Select the type of image to upload",
  info_22: "ID card (front and back)",
  info_23: "Passport",
  info_24: "Driver's License",
  info_25: "Residence permit",
  info_26: "Health Certificate",
  info_27: "Please upload ID photo",
  info_28:
    "Please contact customer service to verify the bank information for payment.",
  info_29: "Account Number",
  info_30: "Bank Name",

  wl_withdraw_money: "Withdrawal amount",
  wl_withdraw_code_201:
    "Withdrawal records are under review, please do not submit again",
  wl_withdraw_code_202: "The bank card information is incorrect",
  wl_withdraw_code_400: "Withdrawal record exception",
  wl_withdraw_code_204: "Incorrect security password",
  wl_withdraw_code_205: "The margin balance cannot be withdrawn",
  wl_withdraw_code_207:
    "The current user star rating cannot withdraw cash, please increase the star rating",
  wl_withdraw_code_208:
    "The current user star rating has reached the maximum number of withdrawals today",

  wl_withdraw_order_01: "All",
  wl_withdraw_order_02: "Not reviewed",
  wl_withdraw_order_03: "Audited",
  wl_withdraw_order_04: "Withdrawal failed",
  wl_withdraw_order_05: "Number",
  wl_withdraw_order_06: "Withdraw amount",
  wl_withdraw_order_07: "Withdrawal method",
  wl_withdraw_order_08: "Withdrawal information",
  wl_withdraw_order_09: "To be reviewed",
  wl_withdraw_order_10: "Approved",
  wl_withdraw_order_11: "Complete",
  wl_withdraw_order_12: "Cancelled",
  wl_withdraw_order_13: "frozen",

  hl_mine_invite_code: "My invitation code",
  hl_help_friend_invite_code: "Help friend invitation code",
  hl_certification_activation_code: "Invitation code",
  hl_plz_write_invite_code: "Please enter the invitation code",
  hl_help_code_0: "The invitation code cannot be empty",
  hl_help_code_201: "The user has bound the invitation code",
  hl_help_code_202: "The invitation code is wrong",
  hl_help_code_203: "The invitation code cannot be bound to yourself",
  gl_visits: "Visits",

  _in_sp: "Product ranking",
  _r_k01: "Ranking",
  _r_k02: "Commodity",
  _r_k03: "Heat",

  _zw_ph: "No product ranking information yet! ",
  _zf_201: "Order status abnormal",
  _zf_202: "Insufficient asset balance",
  _zf_203: "Payment failed",

  _withdraw_order_txt: "Withdraw order",

  shop_num: "Shop number",
  plz_input_shop_num: "Please enter the shop number",
  shop_num_error: "The shop number is incorrect",
  supplier_num_error: "The supplier number is incorrect",
  supplier_num_has_bound: "The supplier number has been bound",

  sc_day_sale: "Daily sales",
  sc_week_sale: "Weekly sales",
  hl_mine_shop: "My Shop",
  hl_shop_name: "Shop name",
  hl_input_shop_name: "Please enter the shop name",
  hl_add_shop_name: "Add shop name",
  hl_update_shop_name: "Modify shop name",
  hl_update_suc: "Modification successful",

  hl_rabate_01: "Lower level commission",
  hl_rabate_02: "Rebate record",
  hl_rabate_03: "Rebate ratio",
  hl_rabate_04: "Total rebate",
  hl_rabate_05: "Total sales",
  hl_rabate_06: "Sales amount",
  hl_rabate_07: "Rebate amount",
  hl_rabate_08: "Registration time",
  hl_rabate_09: "Level 1",
  hl_rabate_10: "Level 2",
  hl_rabate_11: "Level 3",

  dd_order_01: "Cancel order",
  dd_order_02: "Return",
  dd_order_03: "Returning",
  dd_order_04: "Returned",

  _t_p_qr: "Confirm",
  _t_p_txt01:
    "Whether to cancel the order? Cancellation of the order is irrevocable and cannot be found! ! ! ",
  _t_p_txt02: "Please confirm whether this order needs to be returned! ",
  _t_p_txt03: "Cancellation successful",
  _t_p_txt04: "Return request has been submitted",

  showcase_01: "Place an order",
  showcase_02: "Order placed successfully",
  showcase_03: "Please enter your friend ID",

  goods_price: "Price",

  u_pwd_tips: "Please contact customer service to change the security password",

  g_to_be_purchased: "To be purchased",
  g_purchasing_and_shipping: "Purchase and ship",
  g_suer_purchase_order: "Confirm purchase",

  hl_star_rating_text_1: "One star merchant",
  hl_star_rating_text_2: "Two star merchant",
  hl_star_rating_text_3: "Three star merchant",
  hl_star_rating_text_4: "Four star merchant",
  hl_star_rating_text_5: "Five star merchant",

  hl_auth_tips:
    "Real-name authentication has been submitted and is under review...",

  ml_01: "Unread",
  ml_02: "Read",
  ml_03: "Message content...",
  ml_04: "Send",
  ml_05: "Uploading...",

  gl_sale_price: "Sale price",

  wd_fee: "Handling fee",
  wd_receipt: "Actual receipt",
  wd_receipt_way: "Select an existing payment method",
  wd_bank_info: "Bank information",
  wd_bank_info_tip: "Format: Name-Bank name-Opening bank-Card number",
  wd_success: "Withdrawal successful",
  wd_success_tip:
    "Your withdrawal application has been successful and is expected to arrive in your account within 24 hours.",

  g_removed: "Removed",
  g_sale_ing: "On Sale",

  sd_01: "Recommended products",
  sd_02: "More",
  sd_03: "Recommended stores",
  sd_04: "Current products",
  sd_05: "Items",
  sd_06: "View stores",
  sd_07: "Store products",
  sd_08: "Activity Store",
  sd_09: "Illegal Store",

  mc_01: "Signed",
  mc_02: "Paid",
  mc_03: "Signed time",
  mc_04: "Payment time",
  mc_05: "Order number",
  mc_06: "Signed by",
  mc_07: "Purchasing person",
  mc_08: "Address",
  mc_09: "Region",
  mc_10: "Contact buyer",

  lgi_01: "Logistics information",
  lgi_02: "Matching international goods warehouse",
  lgi_03: "Express collection",
  lgi_04: "Transportation",
  lgi_05: "Delivery",

  np_01: "Risk Warning",
  np_02: "System Announcement",
  np_03: "I have read and understand",
  np_04: "News Information",

  sl_01: "One-click listing",

  w_pwd_info_01: "Withdrawal password",
  w_pwd_info_02: "Repeat withdrawal password",
  w_pwd_info_03: "Please enter the withdrawal password again",
  w_pwd_info_04: "The two withdrawal passwords are inconsistent",
  w_pwd_info_05: "Withdrawal password is wrong",
  w_pwd_info_06:
    "Please contact customer service to change the withdrawal password",

  l_g_01: "Email",
  l_g_02: "Please enter your email",
  l_g_03: "Verification code",
  l_g_04: "Please enter the verification code",
  l_g_05: "Send",
  l_g_06: "Login password",
  l_g_07: "Please enter your login password",
  l_g_08: "Repeat login password",
  l_g_09: "Please enter your login password again",
  l_g_10: "Register",
  l_g_11: "Login",
  l_g_12: "Already have an account, go login",
  l_g_13: "Go register",
  l_g_14: "Log out",
  l_g_15: "Confirm to log out?",
  l_g_16: "Language",
  l_g_17: "The two login passwords are inconsistent",
  l_g_18:
    "User information does not exist, please check whether the ID is entered correctly~",
  l_g_19: "Username",
  l_g_20: "Please enter your username",
  l_g_21: "Login and copy",

  h_c_01: "Order revenue",
  h_c_02: "Paid",
  h_c_03: "Waiting for shipment",
  h_c_04: "Confirmed",
  h_c_05: "Sales",
  h_c_06: "Shop invitation code",
  h_c_07: "Please enter the shop invitation code",
  h_c_08: "Invite friends",

  g_c_01: "My Collection",
  g_c_02: "Collected successfully",
};
