import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from "vue-router";
// 和index.js里面的is_New_A保持一致
const is_New_A = true; // A,B,K,Q,C,D,S,P,E为true,其他为false
const is_WEB = true; // 当前分站是否是web版本的

const routes = [
  {
    path: is_WEB == true ? "/" : "/login",
    component: () => import("../views/login.vue"),
  },
  {
    path: is_WEB == true ? "/mine" : is_New_A == true ? "/mine" : "/",
    component: () => import("../views/index.vue"),
  },
  {
    path: "/question",
    component: () => import("../views/question.vue"),
  },
  {
    path: "/feedback",
    component: () => import("../views/feedback.vue"),
  },
  {
    path: "/newcomer",
    component: () => import("../views/newcomer.vue"),
  },
  {
    path: "/help",
    component: () => import("../views/help.vue"),
  },
  {
    path: "/service",
    component: () => import("../views/service.vue"),
  },
  {
    path: "/wallet",
    component: () => import("../views/wallet.vue"),
  },
  {
    path: "/supplier",
    component: () => import("../views/supplier.vue"),
  },
  {
    path: "/goods",
    component: () => import("../views/goodsList.vue"),
  },
  {
    path: "/goods/detail",
    component: () => import("../views/goodsDetail.vue"),
  },
  {
    path: "/showcase",
    component: () => import("../views/showcase.vue"),
  },
  {
    path: "/order",
    component: () => import("../views/order.vue"),
  },
  {
    path: "/order/detail",
    component: () => import("../views/orderDetail.vue"),
  },
  {
    path: "/completeInfo",
    component: () => import("../views/completeInfo.vue"),
  },
  {
    path: "/withdraw",
    component: () => import("../views/withdraw.vue"),
  },
  {
    path: "/ranking",
    component: () => import("../views/ranking.vue"),
  },
  {
    path: "/withdraw_order",
    component: () => import("../views/withdrawOrder.vue"),
  },
  {
    path: "/rebate_record",
    component: () => import("../views/rebateRecord.vue"),
  },
  {
    path: "/recharge",
    component: () => import("../views/recharge.vue"),
  },
  {
    path: "/set_pwd",
    component: () => import("../views/setPwd.vue"),
  },
  {
    path: "/verified",
    component: () => import("../views/verified.vue"),
  },
  {
    path: "/message_list",
    component: () => import("../views/messageList.vue"),
  },
  {
    path: "/message_chat",
    component: () => import("../views/messageChat.vue"),
  },
  {
    path: "/store_detail",
    component: () => import("../views/storeDetail.vue"),
  },
  {
    path: "/store_list",
    component: () => import("../views/storeList.vue"),
  },
  {
    path: "/store_good_list",
    component: () => import("../views/storeGoodList.vue"),
  },
  {
    path:
      is_WEB == true ? "/store_home" : is_New_A == true ? "/" : "/store_home",
    component: () => import("../views/storeHome.vue"),
  },
  {
    path: "/notice_list",
    component: () => import("../views/noticeList.vue"),
  },
  {
    path: "/my_collect",
    component: () => import("../views/myCollect.vue"),
  },
];

const router = createRouter({
  // history: createWebHistory(), // no-#
  history: createWebHashHistory(), // #
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 滚动位置进入新page时回到顶部
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router;
