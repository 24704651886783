export default {
  shop_center_txt: "쇼핑 센터",
  score_txt: "포인트",
  minute_txt: "분",
  wallet_txt: "지갑",
  today_txt: "오늘",
  week_txt: "이번 주",
  month_txt: "이번 달",
  year_txt: "연간",
  main_indicators_txt: "주요 지표",
  sale_price: "판매 총액",
  expected_profit: "예상 수익",
  order_count: "주문 수량",
  goods_click_count: "제품 클릭수",
  supplier_txt: "공급업체",
  friends_help_txt: "친구와 함께해요",
  feedback_txt: "피드백",
  feedback: "피드백",
  online_service_txt_1: "고객 서비스",
  online_service_txt_2: "비즈니스 상담",
  q_and_a_txt: "질문과 답변",
  open_now_txt: "열기",
  certification_activation_code: "인증 활성화 코드",
  plz_write_invite_code: "초대코드를 입력해주세요",
  time_txt: "시간",
  times_txt: "시간",

  wallet_total_assets: "총 자산",
  balance_txt: "이용가능 금액",
  pending_settlement_txt: "결제 대기 중",
  recharge_txt: "충전",
  withdraw_txt: "출금",
  fund_details_txt: "펀드 세부정보",
  no_more_txt: "더 이상",
  no_data_txt: "아직 데이터가 없습니다",
  detail_txt: "세부정보",
  num_txt: "번호",
  money_txt: "금액",
  remark_txt: "비고",
  no_remark_txt: "설명 없음",
  tip_txt: "팁",
  tip_content_txt: "현재 다양한 국가의 판매자가 인수한 통화 문제로 인해",
  need_contact_service: "고객 서비스에 연락해야 합니다",
  contact_service: "고객 서비스에 문의",

  feedback_title: "당신의 의견을 피드백하세요",
  feedback_placeholder: "보안상의 이유로 개인정보를 포함하지 마세요.",
  submit_txt: "제출",
  sure_txt: "확인",
  submit_suc_txt: "제출 성공",

  loading: "로드 중...",

  newer_action: "신규 활동",
  newer_traffic_package: "신입 지원 트래픽 패키지",
  newer_get_millions_of_products: "수백만 개의 제품 받기",
  newer_get: "접수됨",
  newer_receive: "수신",
  newer_action_ruler: "신규 활동 규칙",
  newer_receive_suc: "성공적으로 수신되었습니다",

  help_friends_help: "친구와 함께해요",
  help_your_friends_stores: "친구와 함께해요!",
  get_store_product_heating: "매장 제품 가열 가져오기",
  help_friends_txt: "친구 초대코드 입력하기",
  help_friends_activity_rules: "도움말",
  assistance_details: "지원 세부정보",
  team_list: "팀 목록",
  help_friend_name: "도움말 친구 이름",
  plz_input: "입력하세요",
  plz_input_friend_name: "도움을 줄 친구의 이름을 입력하세요",
  help_suc: "도움말 성공",

  online_service: "온라인 고객 서비스",
  online_24_hour_service: "24시간 온라인 지원",
  customer_service: "고객 서비스",
  copy_and_add_id: "LINE ID 복사 및 추가",
  plz_input_question_detail: "질문의 세부정보를 입력하세요",
  harry_reply_your: "최대한 빨리 답변해 드리겠습니다.",
  click_copy: "복사하려면 클릭하세요",
  copy_suc: "복사 성공",
  copy_fail: "복사 실패",

  overall: "전체적으로",
  cooperate: "협력중",
  cancel: "취소",
  supplier_num: "공급업체 번호",
  no_use_supplier: "사용 가능한 공급업체 없음",
  here_add_supplier: "여기에 새 공급업체 추가",
  add_supplier: "공급업체 추가",
  plz_input_supplier_num: "공급업체 번호를 입력하세요",
  add_suc: "추가 성공",
  remove_suc: "성공적으로 제거되었습니다",

  new_products_launched: "신제품 출시",
  profit_txt: "이익",
  has_been_added: "업로드",
  on_display: "상품등록",
  coming_soon_txt: "업로드중",
  sold_txt: "판매됨",
  removed_txt: "삭제됨",

  order_detail_txt: "주문 세부정보",
  waiting_express_delivery: "빠른 배송을 기다리는 중",
  customer_info: "고객 정보",
  order_good: "상품 주문",
  order_num: "주문 번호",
  order_date: "주문 날짜",
  order_content: "주문 요약",
  purchase_price_txt: "구매 가격",
  income_txt: "소득",

  order_txt: "주문",
  pending_payment: "결제대기중",
  ready_for_shipment: "배송준비",
  shipped_txt: "배송중",
  paid_txt: "거래완료",
  good_num: "항목 번호",
  pay_and_ship: "결제 및 배송",
  pay_suc: "결제 성공",
  no_order: "아직 주문이 없습니다",
  no_order_tips: "여기에서 주문 및 반품을 추적할 수 있습니다",
  suer_order: "결제 확인",
  pay_way: "결제 수단",
  wallet_pay: "지갑 결제",
  sure_pay: "결제 확인",
  paying_txt: "결제 중...",

  manage_showcase: "쇼케이스 관리",
  add_new_good: "새 제품 추가",
  heating_txt: "난방",
  promotion: "프로모션",
  promotion_tip: "프로모션 팁",
  promotion_tip_txt: "현재 포인트가 200 미만이므로 제품 결제가 불가능합니다.",
  copy_link: "링크 복사",
  scan_qr_code: "QR 코드 스캔",

  about_good: "제품 정보",
  specification: "사양",
  product_overview: "제품 개요",

  top_tips_1: "Douyin 매장을 온라인으로 전환하세요",
  top_tips_2: "현재 내부 초대만 지원합니다",
  active_place_holeder: "입력하세요",

  promotion_01: "프로모션",
  promotion_02: "프로모션 금액",
  promotion_03: "프로모션 금액을 입력해주세요",
  promotion_04: "프로모션 확인",
  promotion_05: "프로모션 성공",

  bao_txt: "노출",
  bal_no: "잔액 부족",

  wallet_type_01: "시스템 수동 충전",
  wallet_type_02: "수동 출금",
  wallet_type_03: "결제",
  wallet_type_04: "시스템 공제",
  wallet_type_05: "이익소득",
  wallet_type_06: "결제 공제",
  wallet_type_07: "유통 소비",
  wallet_type_08: "프로모션 수익",
  wallet_type_09: "보상 강화",
  wallet_type_10: "무료로 등록하세요",
  wallet_type_11: "회전율 보상",
  wallet_type_12: "출금 거부됨",

  all_orders: "모든 주문",
  mine_orders: "내 주문",
  mine_services: "내 서비스",
  _tzz: "점프 중...",

  info_01: "이름",
  info_02: "이메일",
  info_03: "연락처 정보",
  info_04: "성별",
  info_05: "기밀",
  info_06: "남성",
  info_07: "여성",
  info_08: "생년월일(yyyy-MM-dd)",
  info_09: "국가",
  info_10: "도시",
  info_11: "카드번호",
  info_12: "카드 종류",
  info_13: "카드 발급기관",
  info_14: "만료일(yyyy-MM-dd)",
  info_15: "CVV 코드",
  info_16: "보안 비밀번호",
  info_17: "보안 비밀번호를 반복하세요",
  info_18: "보안 비밀번호를 다시 입력해주세요",
  info_19: "두 개의 보안 비밀번호가 일치하지 않습니다",
  info_20: "신분증번호/여권번호",
  info_21: "업로드할 이미지 유형을 선택하세요",
  info_22: "신분증 (앞면 및 뒷면)",
  info_23: "여권",
  info_24: "운전면허증",
  info_25: "거주 허가",
  info_26: "건강 증명서",
  info_27: "신분증 사진을 업로드해주세요",
  info_28: "결제를 위한 은행 정보를 확인하려면 고객 서비스에 문의하세요.",
  info_29: "계좌 번호",
  info_30: "은행 이름",

  wl_withdraw_money: "출금 금액",
  wl_withdraw_code_201: "인출 기록이 검토 중입니다. 다시 제출하지 마십시오.",
  wl_withdraw_code_202: "은행카드 정보가 올바르지 않습니다",
  wl_withdraw_code_400: "출금 기록 예외",
  wl_withdraw_code_204: "잘못된 보안 비밀번호",
  wl_withdraw_code_205: "마진 잔액을 인출할 수 없습니다",
  wl_withdraw_code_207:
    "현재 사용자의 별점은 철회할 수 없습니다. 별점을 높여주세요.",
  wl_withdraw_code_208:
    "현재 사용자의 별점은 오늘 최대 인출 횟수에 도달했습니다.",

  wl_withdraw_order_01: "모두",
  wl_withdraw_order_02: "미신사",
  wl_withdraw_order_03: "인출완료",
  wl_withdraw_order_04: "출금 실패",
  wl_withdraw_order_05: "번호",
  wl_withdraw_order_06: "인출 금액",
  wl_withdraw_order_07: "출금 방법",
  wl_withdraw_order_08: "출금 정보",
  wl_withdraw_order_09: "검토 예정",
  wl_withdraw_order_10: "승인됨",
  wl_withdraw_order_11: "완료",
  wl_withdraw_order_12: "취소됨",
  wl_withdraw_order_13: "동결",

  hl_mine_invite_code: "내 초대 코드",
  hl_help_friend_invite_code: "친구 초대 코드 입력",
  hl_certification_activation_code: "초대 코드",
  hl_plz_write_invite_code: "초대코드를 입력해주세요",
  hl_help_code_0: "초대코드는 비워둘 수 없습니다.",
  hl_help_code_201: "사용자가 초대 코드를 연결했습니다",
  hl_help_code_202: "초대코드가 잘못되었습니다.",
  hl_help_code_203: "초대 코드는 본인에게 바인딩될 수 없습니다.",
  gl_visits: "방문 횟수",

  _in_sp: "상품 순위",
  _r_k01: "순위",
  _r_k02: "상품",
  _r_k03: "조회수",

  _zw_ph: "아직 상품순위 정보가 없습니다! ",
  _zf_201: "주문 상태가 비정상적",
  _zf_202: "자산 잔액 부족",
  _zf_203: "결제 실패",

  _withdraw_order_txt: "인출금 주문",

  shop_num: "매장 번호",
  plz_input_shop_num: "상점 번호를 입력해주세요",
  shop_num_error: "상점 번호가 정확하지 않습니다",
  supplier_num_error: "공급업체 번호가 올바르지 않습니다",
  supplier_num_has_bound: "공급업체 번호가 연결되었습니다",

  sc_day_sale: "일일 판매",
  sc_week_sale: "주간 판매",
  hl_mine_shop: "내 상점",
  hl_shop_name: "상점 이름",
  hl_input_shop_name: "상점 이름을 입력하세요",
  hl_add_shop_name: "상점 이름 추가",
  hl_update_shop_name: "상점 이름 수정",
  hl_update_suc: "수정 성공",

  hl_rabate_01: "커미션",
  hl_rabate_02: "커미션 기록",
  hl_rabate_03: "커미션 비율",
  hl_rabate_04: "총 커미션 금액",
  hl_rabate_05: "총 판매량",
  hl_rabate_06: "판매 금액",
  hl_rabate_07: "리베이트 금액",
  hl_rabate_08: "등록 시간",
  hl_rabate_09: "레벨 1 지원",
  hl_rabate_10: "레벨 2 지원",
  hl_rabate_11: "레벨 3 지원",

  dd_order_01: "주문 취소",
  dd_order_02: "반품",
  dd_order_03: "반품",
  dd_order_04: "반품됨",

  _t_p_qr: "확인",
  _t_p_txt01:
    "주문 취소 여부? 주문 취소는 취소할 수 없으며 확인할 수 없습니다! ! ! ",
  _t_p_txt02: "이 주문을 반품해야 하는지 확인하세요! ",
  _t_p_txt03: "취소 성공",
  _t_p_txt04: "반품 요청이 제출되었습니다",

  showcase_01: "주문하기",
  showcase_02: "주문이 성공적으로 완료되었습니다",
  showcase_03: "친구 아이디를 입력해주세요",

  goods_price: "가격",

  u_pwd_tips: "보안 비밀번호를 변경하려면 고객 서비스에 문의하세요",

  g_to_be_purchased: "구매 예정",
  g_purchasing_and_shipping: "구매 및 배송",
  g_suer_purchase_order: "구매 확인",

  hl_star_rating_text_1: "별 1개 상인",
  hl_star_rating_text_2: "별 2개 상인",
  hl_star_rating_text_3: "별 3개 상인",
  hl_star_rating_text_4: "별 4개 상인",
  hl_star_rating_text_5: "별 5개 상인",

  hl_auth_tips: "실명인증이 제출되었으며 검토중입니다...",

  ml_01: "읽지 않음",
  ml_02: "읽기",
  ml_03: "메시지 내용...",
  ml_04: "보내기",
  ml_05: "업로드 중...",

  gl_sale_price: "판매 가격",

  wd_fee: "취급수수료",
  wd_receipt: "실제 도착",
  wd_receipt_way: "기존 결제 방법을 선택하세요",
  wd_bank_info: "은행 정보",
  wd_bank_info_tip: "형식: 이름-은행명-계좌 은행-카드번호",
  wd_success: "철회 성공",
  wd_success_tip:
    "귀하의 인출 신청이 성공적으로 완료되었으며 24시간 이내에 귀하의 계좌에 도착할 것으로 예상됩니다.",

  g_removed: "제거됨",
  g_sale_ing: "세일 중",

  sd_01: "추천 제품",
  sd_02: "더 보기",
  sd_03: "추천 매장",
  sd_04: "현재 제품",
  sd_05: "하나",
  sd_06: "스토어 보기",
  sd_07: "스토어 제품",
  sd_08: "활동 저장소",
  sd_09: "불법 상점",

  mc_01: "서명됨",
  mc_02: "유료",
  mc_03: "서명 시간",
  mc_04: "결제 시간",
  mc_05: "주문 번호",
  mc_06: "서명",
  mc_07: "구매자",
  mc_08: "주소",
  mc_09: "지역",
  mc_10: "구매자에게 연락하기",

  lgi_01: "물류정보",
  lgi_02: "국제 물품 창고 일치",
  lgi_03: "특급 배송 진행 중",
  lgi_04: "배송 중",
  lgi_05: "배달 중",

  np_01: "안내",
  np_02: "시스템 공지",
  np_03: "읽고 이해했습니다.",
  np_04: "뉴스",

  sl_01: "원클릭 목록",

  w_pwd_info_01: "출금 비밀번호",
  w_pwd_info_02: "출금 비밀번호를 반복하세요",
  w_pwd_info_03: "출금 비밀번호를 다시 입력해주세요",
  w_pwd_info_04: "두 개의 출금 비밀번호가 일치하지 않습니다",
  w_pwd_info_05: "잘못된 출금 비밀번호",
  w_pwd_info_06: "출금 비밀번호를 변경하려면 고객 서비스에 문의하세요",

  l_g_01: "이메일",
  l_g_02: "이메일 주소를 입력해주세요",
  l_g_03: "인증코드",
  l_g_04: "인증코드를 입력해주세요",
  l_g_05: "보내기",
  l_g_06: "로그인 비밀번호",
  l_g_07: "로그인 비밀번호를 입력하세요",
  l_g_08: "로그인 비밀번호를 반복하세요",
  l_g_09: "로그인 비밀번호를 다시 입력해주세요",
  l_g_10: "등록",
  l_g_11: "로그인",
  l_g_12: "이미 계정이 있습니다. 로그인하세요.",
  l_g_13: "등록으로 이동",
  l_g_14: "로그아웃",
  l_g_15: "로그아웃을 확인하시겠습니까?",
  l_g_16: "언어",
  l_g_17: "두 개의 로그인 비밀번호가 일치하지 않습니다.",
  l_g_18:
    "사용자 정보가 존재하지 않습니다. 아이디가 올바르게 입력되었는지 확인해주세요~",
  l_g_19: "사용자 이름",
  l_g_20: "사용자 이름을 입력하세요",
  l_g_21: "로그인하고 복사하세요",

  h_c_01: "주문 이익",
  h_c_02: "유료",
  h_c_03: "배송 예정",
  h_c_04: "확인됨",
  h_c_05: "판매",
  h_c_06: "가게 개설 초대 코드",
  h_c_07: "상점 초대코드를 입력해주세요",
  h_c_08: "친구 초대",

  g_c_01: "내 컬렉션",
  g_c_02: "수집 성공",
};
